import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/translation/translation.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {

  mouseOver = false;

  constructor(public translationService: TranslationService) { }

  ngOnInit() {
  }

  public setLanguage(language: string) {
    this.translationService.setAppLanguage(language);
    this.mouseOver = false;
  }

}
