import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieService} from './cookie.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  public downloadFile(downloadRest: string): Observable<HttpResponse<Blob>> {
    return this.http.get(downloadRest, {observe: 'response', responseType: 'blob'});
  }


  public uploadToSignedURL(fileName: string): Observable<any> {
    const header = {Authorization: `Bearer ${this.cookieService.getCookie().jwtToken}`};
    const url = environment.apiUrl + '/restaurants/images/' + fileName;

    return this.http.get(url, {headers: header, observe: 'response'})

  }

  public upload(url: string, file) {
    return this.http.put<any>(url, file, {reportProgress: true, responseType: 'json'});
  }
}
