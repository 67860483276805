import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

    public readonly storageElementNameSelectedFilter: string = 'selectedFilter';
    public readonly storageElementNameSelectedSorting: string = 'selectedSorting';
    public readonly storageElementNameSelectedProducts: string = 'selectedProducts';

    constructor() {
    }

    public saveArrayToSessionStorage(storageElementName: string, array: any[]) {
        sessionStorage.setItem(storageElementName, JSON.stringify(array));
    }

    public getArrayFromSessionStorage(storageElementName: string): any[] {
        return JSON.parse(sessionStorage.getItem(storageElementName));
    }

    public saveValueToSessionStorage(storageElementName: string, value: string) {
        sessionStorage.setItem(storageElementName, value);
    }

    public getValueFromSessionStorage(storageElementName: string): string {
        return sessionStorage.getItem(storageElementName);
    }

    public removeFromSessionStorage(storageElementName: string) {
        sessionStorage.removeItem(storageElementName);
    }

}