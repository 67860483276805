import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Menu} from 'src/app/models/menu';
import {MenuService} from 'src/app/services/menu.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MenuPublishDialogComponent} from '../dialogs/menu-publish-dialog/menu-publish-dialog.component';
import {MenuRevertDialogComponent} from '../dialogs/menu-revert-dialog/menu-revert-dialog.component';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Section} from '../../models/section';
import {Product} from '../../models/product';
import {UserService} from 'src/app/services/user.service';
import {User} from 'src/app/models/user';
import {Restaurant} from '../../models/restaurant';
import {FileService} from 'src/app/services/file.service';
import {finalize} from 'rxjs/operators';
import {MenuDeleteGroupDialogComponent} from '../dialogs/menu-delete-group-dialog/menu-delete-group-dialog.component';
import {formatDate} from '@angular/common';
import {Globals} from 'src/app/models/globals';
import { FoodAdditivesDialogComponent } from './food-additives-dialog/food-additives-dialog.component';
import { TranslationService } from 'src/app/translation/translation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.component.html',
  styleUrls: ['./menu-edit.component.css']
})
export class MenuEditComponent implements OnInit {


  constructor(private menuService: MenuService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private userService: UserService,
              private fileService: FileService,
              private renderer: Renderer2,
              public translationService: TranslationService,
              private route: ActivatedRoute) {
  }

  focusedInputField = null;

  openDialogFoodAdditivesBoolean = false;

  mobile = false;

  priceRegex = /^(\d*)(\,)?(\d*)?$/;
  hexColorRegex = /^[a-zA-Z0-9]*$/;

  user = new User();
  menu = new Menu();
  restaurant = new Restaurant();
  menuSortState = '';
  menuCurrentFiltersArray: string[] = [];
  connectedListsIds: string[] = [];

  settingGoogleAutoTranslate;
  settingPriceSortEnabled;
  settingPriceTotalEnabled;

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  datePipe: any;

  files = [];
  uploadingLogoFile = false;

  @Input()
  demo = false;

  defaultLanguages = [];
  defaultCurrencies = [];

  private static getRandomString(length) {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (!this.demo) {
      return false;
    }
  }

  ngOnInit() {
    this.defaultLanguages = this.translationService.getMenuLanguages();
    this.defaultCurrencies = this.translationService.getMenuCurrencies();

    if (window.screen.width <= 768) {
      this.mobile = true;
    }

    if (!this.demo) {
      this.userService.getUser().subscribe(result => {
        this.user = result;
      });
    } else {
      this.user = {
        userDisplayName: 'demo',
        urlId: Globals.demoUrlId
      }
    }

    if (!this.demo) {
      this.getMenu();
    } else {
      this.getMenuDemo();
    }
  }


  private isHexColor(color: string): boolean {
    let result = false;

    if (color.length === 6) {
      const regex = new RegExp(this.hexColorRegex);

      if (regex.test(color)) {
        result = true;
      }
    }

    return result;
  }

  saveMenuStyleColorCustomBackgroundColor(color: string) {
    color = color.replace('#', '');

    if (this.isHexColor(color)) {
      this.menu.menuStyle.customColor.backgroundColor = '#' + color;
      document.documentElement.style.setProperty('--menuStyleColor_custom_background-color', this.menu.menuStyle.customColor.backgroundColor);
    } else {
      this.menu.menuStyle.customColor.backgroundColor = '';
      document.documentElement.style.setProperty('--menuStyleColor_custom_background-color', this.menu.menuStyle.customColor.backgroundColor);
    }
  }

  saveMenuStyleColorCustomTextColor(color: string) {
    color = color.replace('#', '');

    if (this.isHexColor(color)) {
      this.menu.menuStyle.customColor.textColor = '#' + color;
      document.documentElement.style.setProperty('--menuStyleColor_custom_text-color', this.menu.menuStyle.customColor.textColor);
    } else {
      this.menu.menuStyle.customColor.textColor = '';
      document.documentElement.style.setProperty('--menuStyleColor_custom_text-color', this.menu.menuStyle.customColor.textColor);
    }
  }

  saveMenuStyleColorCustomAccentColor(color: string) {
    color = color.replace('#', '');

    if (this.isHexColor(color)) {
      this.menu.menuStyle.customColor.accentColor = '#' + color;
      document.documentElement.style.setProperty('--menuStyleColor_custom_accent-color', this.menu.menuStyle.customColor.accentColor);
    } else {
      this.menu.menuStyle.customColor.accentColor = '';
      document.documentElement.style.setProperty('--menuStyleColor_custom_accent-color', this.menu.menuStyle.customColor.accentColor);
    }
  }

  getMenuStyleColorCustomBackgroundColor(): string {
    return document.documentElement.style.getPropertyValue('--menuStyleColor_custom_background-color').replace('#', '');
  }

  getMenuStyleColorCustomTextColor(): string {
    return document.documentElement.style.getPropertyValue('--menuStyleColor_custom_text-color').replace('#', '');
  }

  getMenuStyleColorCustomAccentColor(): string {
    return document.documentElement.style.getPropertyValue('--menuStyleColor_custom_accent-color').replace('#', '');
  }

  moveGroup(groupList: any[], previousIndex: number, currentIndex: number) {
    moveItemInArray(groupList, previousIndex, currentIndex);
  }

  dropGroup(event: CdkDragDrop<string[]>, groupList: any[]) {
    moveItemInArray(groupList, event.previousIndex, event.currentIndex);
  }

  dropFood(event: CdkDragDrop<string[]>, foodList: any[]) {
    if (event.previousContainer.id === event.container.id) {
      moveItemInArray(foodList, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(this.menu.sections.filter(section => section.order === Number(event.previousContainer.id))[0].products,
        foodList,
        event.previousIndex,
        event.currentIndex);
    }
  }

  public addNewEmptySection(index: number) {
    const emptySection = new Section();
    emptySection.name = '';
    emptySection.description = '';
    emptySection.hidden = false;
    emptySection.products = [];
    emptySection.isSubsection = false;

    let nextId = 0;
    if (this.menu.sections.length > 0) {
      nextId = Math.max(...this.menu.sections.map(element => element.order)) + 1;
    }
    emptySection.order = nextId;

    this.connectedListsIds.push(nextId.toString());

    this.menu.sections.splice(index, 0, emptySection);
  }

  public addNewEmptyProduct(section: Section, addToTop: boolean) {
    const emptyProduct = new Product();
    emptyProduct.name = '';
    emptyProduct.description = '';
    emptyProduct.hidden = false;
    emptyProduct.metaTags = [];

    let nextId = 0;
    if (section.products !== null && section.products.length > 0) {
      nextId = Math.max(...section.products.map(element => element.order)) + 1;
    }
    emptyProduct.order = nextId;

    const sectionNameUpperCase = section.name.toUpperCase();

    if (sectionNameUpperCase.includes('GETRÄNK') || sectionNameUpperCase.includes('DRINK') || sectionNameUpperCase.includes('SHAKE') || sectionNameUpperCase.includes('WASSER') || sectionNameUpperCase.includes('BIER') || sectionNameUpperCase.includes('WEIN') || sectionNameUpperCase.includes('COCKTAIL')) {
      emptyProduct.metaTags = ['drink'];
    }

    if (section.products === null) {
      section.products = [];
    }

    if (addToTop) {
      section.products.splice(0, 0, emptyProduct);
    } else {
      section.products.splice(section.products.length, 0, emptyProduct);
    }


  }

  public subGroupToggle(section: Section, set: boolean) {
    section.isSubsection = set;
  }

  public deleteGroup(section: Section) {
    this.menu.sections = this.menu.sections.filter(item => item !== section);
    this.connectedListsIds = this.connectedListsIds.filter(item => item !== section.order.toString());
  }

  public deleteProduct(section: Section, product: Product) {
    section.products = section.products.filter(item => item !== product);
  }

  public openDialogFoodAdditives() {
    this.openDialogFoodAdditivesBoolean = true;
  }

  public closeDialogFoodAdditives() {
    this.openDialogFoodAdditivesBoolean = false;
  }

  public contentCopy(textToCopy: string) {
    this.copyToClipboard(textToCopy);

    this.snackBar.open('Kopiert!', '', {
      duration: 1000,
    });
  }

  private copyToClipboard(textToCopy: string){
    var copyElement = document.createElement("textarea");
    copyElement.style.position = 'fixed';
    copyElement.style.opacity = '0';
    copyElement.textContent = textToCopy;
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(copyElement);
    copyElement.select();
    document.execCommand('copy');
    body.removeChild(copyElement);
  }

  insertTextIntoFocudesInputFieldPosition(text: string) {
    if (this.focusedInputField !== null) {
      const caretPosition = this.focusedInputField.selectionStart;
      this.focusedInputField.value = this.focusedInputField.value.substring(0, caretPosition) + text + this.focusedInputField.value.substring(caretPosition, this.focusedInputField.value.length);
      this.focusedInputField.focus();
      this.focusedInputField.selectionStart = caretPosition + text.length;
      this.focusedInputField.selectionEnd = this.focusedInputField.selectionStart;
    } else {
      this.snackBar.open('Bitte klicke die gewünschte Stelle im Produkt-Text an', '', { duration: 4000 });
    }
  }

  getFocusedProductDescriptionTextArea(productOrder: number) {
    if (productOrder >= 0) {
      this.focusedInputField = document.getElementById('productDescriptionTextArea-' + productOrder);
    } else {
      this.focusedInputField = null;
    }
  }

  getFocusedProductNameTextArea(productOrder: number) {
    if (productOrder >= 0) {
      this.focusedInputField = document.getElementById('productNameTextArea-' + productOrder);
    } else {
      this.focusedInputField = null;
    }
  }

  public menuFilterSwitch(filter: string, types: string[]) {
    if (types.includes(filter)) {
      types.splice(types.indexOf(filter), 1);
      if (filter === 'vegetarian') {
        if (types.includes('vegan')) {
          types.splice(types.indexOf('vegan'), 1);
        }
      }
    } else {
      types.push(filter);
      if (filter === 'vegan') {
        if (!types.includes('vegetarian')) {
          types.push('vegetarian');
        }
      }
    }
  }

  public onClickUploadFile(event: any) {
    if (!this.demo) {
      const fileUpload = this.fileUpload.nativeElement;

      fileUpload.onchange = () => {
        const file = fileUpload.files[0];
        this.files.unshift({data: file, inProgress: false, progress: 0});
        this.uploadFile(this.files[0], event);
      };
      fileUpload.click();
    } else {
      this.showDemoMessage();
    }
  }

  public deleteLogo() {
    if (!this.demo) {
      this.menu.logoUrl = '';
    } else {
      this.showDemoMessage();
    }
  }

  uploadFile(file, event: any) {
    const formData = new FormData();

    const dateNow = new Date();

    const fileName = this.user.urlId + '_' + MenuEditComponent.getRandomString(25) + '_' + formatDate(dateNow, 'yyyy-MM-dd-hh-mm-ss-SSS', 'en') + '.' + file.data.name.split('.')[1];
    formData.append('file', file.data, fileName);

    file.uploadToServerError = false;
    file.uploadStartTime = dateNow;
    file.inProgress = true;

    file.fileSizeKbTotal = Math.ceil(file.data.size / 1024);
    if (file.fileSizeKbTotal <= 10 * 1024) {
      this.uploadingLogoFile = true;
      event.target.disabled = true;
      this.renderer.addClass(event.target, 'spinner');

      let uploadedFile: FormDataEntryValue;
      uploadedFile = formData.get('file');
      this.fileService.uploadToSignedURL(fileName)
        .pipe(
          finalize(() => {
            this.uploadingLogoFile = false;
            event.target.disabled = false;
            this.renderer.removeClass(event.target, 'spinner');
          }),
        ).subscribe(
        response => {
          this.fileService.upload(response.body, uploadedFile)
            .pipe(
              finalize(() => {
                this.uploadingLogoFile = false;
                event.target.disabled = false;
                this.renderer.removeClass(event.target, 'spinner');
              }),
            ).subscribe(() => {
              // @ts-ignore
              this.menu.logoUrl = 'https://hello-menu-restaurant-images.s3.eu-central-1.amazonaws.com/' + uploadedFile.name;
            }
          );
        }
      );
    } else {
      this.snackBar.open('Die Datei darf nicht gößer als 10MB sein', '', {duration: 5000});
    }

  }

  public openDialogDeleteGroup(section: Section) {
    if (section.products !== null && section.products.length !== 0) {
      const dialogRef = this.dialog.open(MenuDeleteGroupDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.deleteGroup(section);
        }
      });
    } else {
      this.deleteGroup(section);
    }
  }

  private saveSettings() {
    this.menu.settingGoogleAutoTranslate = this.settingGoogleAutoTranslate;
    this.menu.settingPriceSortEnabled = this.settingPriceSortEnabled;
    this.menu.settingPriceTotalEnabled = this.settingPriceTotalEnabled;
  }

  public openDialogMenuPublish(event: any) {
    if (!this.demo) {
      const dialogRef = this.dialog.open(MenuPublishDialogComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.setLoadingSpinner(event, true);

          this.setOrderValuesAndActiveTypes();
          this.saveSettings();

          const oldMenuModifiedDate = this.menu.modified;
          const oldRestaurantModifiedDate = this.restaurant.modified; // Holds Menu Publish Date

          // Update dates for success
          const currentDate = new Date();
          this.menu.modified = currentDate;
          this.restaurant.modified = currentDate;

          this.restaurant.menuDraft = this.menu;

          this.menuService.putMenu(this.menu)
            .subscribe(response => {
              if (response.status === 200) {
                this.menuService.putRestaurant(this.restaurant).subscribe(response => {
                  if (response.status === 200) {
                    this.setLoadingSpinner(event, false);
                  } else {
                    this.restaurant.modified = oldRestaurantModifiedDate;
                    this.snackBar.open('Bei der Veröffentlichung der Speisekarte ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
                    this.setLoadingSpinner(event, false);
                  }
                }, error => {
                  this.restaurant.modified = oldRestaurantModifiedDate;
                  this.snackBar.open('Bei der Veröffentlichung der Speisekarte ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
                  this.setLoadingSpinner(event, false);
                });
              } else {
                this.menu.modified = oldMenuModifiedDate;
                this.restaurant.modified = oldRestaurantModifiedDate;
                this.snackBar.open('Beim Speichern des Entwurfs ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
                this.setLoadingSpinner(event, false);
              }
            }, error => {
              this.menu.modified = oldMenuModifiedDate;
              this.restaurant.modified = oldRestaurantModifiedDate;
              this.snackBar.open('Beim Speichern des Entwurfs ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
              this.setLoadingSpinner(event, false);
            });
        }
      });
    } else {
      this.showDemoMessage();
    }
  }

  private setLoadingSpinner(event: any, set: boolean) {
    if (set) {
      event.target.disabled = true;
      this.renderer.addClass(event.target, 'spinner');
    } else {
      event.target.disabled = false;
      this.renderer.removeClass(event.target, 'spinner');
    }
  }

  public openDialogMenuRevert() {
    const dialogRef = this.dialog.open(MenuRevertDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        location.reload();
      }
    });
  }

  private setOrderValuesAndActiveTypes() {
    this.menu.activeMetaTags = [];

    let productOrder = 0;

    this.menu.sections.forEach((section, index) => {
      section.order = index;
      section.metaTags = [];

      if (section.products !== null && section.products.length > 0) {
        section.products.forEach((product, productIndex) => {
          product.order = productOrder;
          productOrder++;

          if (product.metaTags !== null && product.metaTags.length > 0) {
            product.metaTags.forEach(type => {
              if (!this.menu.activeMetaTags.includes(type)) {
                this.menu.activeMetaTags.push(type);
              }
              if (!section.metaTags.includes(type)) {
                section.metaTags.push(type);
              }
            });
          }

        });
      } else {
        section.metaTags = null;
      }
    });

    this.menu.sections.forEach((section, index) => {
      for (let step = index; step > 0; step = step - 1) {
        let previousStep = step - 1;
        if (section.isSubsection === true && section.metaTags !== null && section.metaTags.length > 0 && this.menu.sections[previousStep] && this.menu.sections[previousStep].isSubsection === false) {
          if (this.menu.sections[previousStep].metaTags === null) {
            this.menu.sections[previousStep].metaTags = section.metaTags;
          } else {
            this.menu.sections[previousStep].metaTags = [...this.menu.sections[previousStep].metaTags, ...section.metaTags];
            // TODO: why this doesn't work?
            // section.metaTags.forEach(type => {
            //   if (!this.menu.sections[previousStep].metaTags.includes(type)) {
            //     this.menu.sections[previousStep].metaTags.push(type);
            //   }
            // });
          }
          break;
        }
      }
    });
  }

  private showDemoMessage() {
    this.snackBar.open(this.translationService.getAppTranslation('menuEdit', 'demo', 'info'), '', {duration: 6000});
  }

  public saveMenuDraft(event: any) {
    if (!this.demo) {
      this.setLoadingSpinner(event, true);

      this.setOrderValuesAndActiveTypes();
      this.saveSettings();

      const oldMenuModifiedDate = this.menu.modified;

      // Update dates for success
      const currentDate = new Date();
      this.menu.modified = currentDate;

      this.restaurant.menuDraft = this.menu;

      this.menuService.putRestaurant(this.restaurant)
        .subscribe(response => {
          if (response.status === 200) {
            this.setLoadingSpinner(event, false);
          } else {
            this.menu.modified = oldMenuModifiedDate;
            this.snackBar.open('Beim Speichern des Entwurfs ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
            this.setLoadingSpinner(event, false);
          }
        }, error => {
          this.menu.modified = oldMenuModifiedDate;
          this.snackBar.open('Beim Speichern des Entwurfs ist ein Problem aufgetreten. Bitte versuchen Sie in Kurze erneut.', '', {duration: 5000});
          this.setLoadingSpinner(event, false);
        });
    } else {
      this.showDemoMessage();
    }
  }

  public convertStringToPrice(price: string): number {
    price = price.trim();

    if (price !== '') {
      if (price.length > 1 && price.substr(0, 1) === '0') {
        // remove leading zeros
        price = price.replace(/^0+/, '');
      }
      if (price.length > 0 && price.substr(0, 1) === ',') {
        // add leading zero
        price = '0' + price;
      }
      if (price.substr(price.length - 1, 1) === ',') {
        // add 0 after ,
        price = price + '0';
      }
      // remove '.' (1000 separator)
      price = price.replace('.', '');

      const regex = new RegExp(this.priceRegex);

      if (regex.test(price)) {
        price = price.replace(',', '.');

        return Number(price);
      } else {
        return null;
      }
    } else {
      return 0;
    }
  }

  public validatePrice(event): boolean {
    const price = event.target.value + String.fromCharCode(event.keyCode);

    const regex = new RegExp(this.priceRegex);

    return regex.test(price);
  }

  private getMenu() {
    this.menuService.getRestaurant()
      .subscribe(response => {
        if (response.status === 200) {
          this.restaurant = response.body;
          this.menu = this.restaurant.menuDraft;

          this.settingGoogleAutoTranslate = this.menu.settingGoogleAutoTranslate;
          this.settingPriceSortEnabled = this.menu.settingPriceSortEnabled;
          this.settingPriceTotalEnabled = this.menu.settingPriceTotalEnabled;

          if (this.menu.menuStyle.color === '') {
            this.menu.menuStyle.color = 'light';
          }

          this.saveMenuStyleColorCustomBackgroundColor(this.menu.menuStyle.customColor.backgroundColor);
          this.saveMenuStyleColorCustomTextColor(this.menu.menuStyle.customColor.textColor);
          this.saveMenuStyleColorCustomAccentColor(this.menu.menuStyle.customColor.accentColor);

          this.menu.sections.forEach(element => {
            this.connectedListsIds.push(element.order.toString());
          });
        }
      }, error => {
      });
  }

  // TODO: Code Verdopplung
  private getMenuDemo() {
    this.menuService.getMenu('DEMO')
      .subscribe(response => {
        if (response.status === 200) {
          this.menu = response.body;

          this.settingGoogleAutoTranslate = this.menu.settingGoogleAutoTranslate;
          this.settingPriceSortEnabled = this.menu.settingPriceSortEnabled;
          this.settingPriceTotalEnabled = this.menu.settingPriceTotalEnabled;

          if (this.menu.menuStyle.color === '') {
            this.menu.menuStyle.color = 'light';
          }

          this.saveMenuStyleColorCustomBackgroundColor(this.menu.menuStyle.customColor.backgroundColor);
          this.saveMenuStyleColorCustomTextColor(this.menu.menuStyle.customColor.textColor);
          this.saveMenuStyleColorCustomAccentColor(this.menu.menuStyle.customColor.accentColor);

          this.menu.sections.forEach(element => {
            this.connectedListsIds.push(element.order.toString());
          });
        }
      }, error => {
      });
  }

}
