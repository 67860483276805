import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MenuService} from 'src/app/services/menu.service';
import {Menu} from 'src/app/models/menu';
import {DOCUMENT, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Section} from '../../models/section';
import {Product} from '../../models/product';
import {filter} from 'rxjs/operators';
import {CookieService} from '../../services/cookie.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SelectedProductsDialogComponent } from './selected-products-dialog/selected-products-dialog.component';
import { TranslationService } from 'src/app/translation/translation.service';
import { Globals } from 'src/app/models/globals';
import { SessionStorageService } from 'src/app/services/session-storage.service';

registerLocaleData(localeDe);

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {

  @ViewChild('filterBlock', {static: false}) filterBlockElement: ElementRef;

  @ViewChild('stickyMenu', {static: false}) menuElement: ElementRef;

  sticky: boolean = false;

  initialMenuStyleColor = '';
  menuStyleColorCustomBackgroundColorLightOrDark = '';

  buttonTranslate = false;

  menu = new Menu();
  menuSortState = '';
  menuCurrentFiltersArray: string[] = [];

  currencySymbol = '€';

  defaultLanguage = 'de';
  googleLanguage = '';
  currentlanguage = '';

  lastSelectionElementId;

  selectedProducts: Product[] = [];

  constructor(@Inject(DOCUMENT) private document: Document,
              private menuService: MenuService,
              private route: ActivatedRoute,
              private router: Router,
              private cookieService: CookieService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              public translationService: TranslationService,
              public sessionStorage: SessionStorageService) {
    this.resetGoogTranslateCookieOnPageRefresh();
    this.currentlanguage = this.defaultLanguage;
    if (this.sessionStorage.getValueFromSessionStorage(this.sessionStorage.storageElementNameSelectedSorting)) {
      this.menuSortState = this.sessionStorage.getValueFromSessionStorage(this.sessionStorage.storageElementNameSelectedSorting);
    }
  }

  ngOnInit() {
    this.getMenu(this.route.snapshot.paramMap.get('id'));

    setInterval(()=> {
      this.googleLanguage = this.getLanguageFromCookieGoogTrans();

      if (this.googleLanguage === '') {
        this.currentlanguage = this.defaultLanguage;
      } else {
        this.currentlanguage = this.googleLanguage;
      }

      if (this.googleLanguage === this.defaultLanguage) {
        this.resetGoogTranslateCookie();
        location.reload();
      }
    }, 200);

  }

  private getLanguageFromCookieGoogTrans(): string {
    const cookies: Array<string> = this.document.cookie.split(';');

    for (const cookieIndex in cookies) {
      if (cookies[cookieIndex].trim().startsWith('googtrans')) {
        const cookie = cookies[cookieIndex].trim();
        const cookieContent = cookie.replace(name + '=', '');
        const decodedCookieValue = decodeURIComponent(cookieContent).split('googtrans', 2)[1];
        const decodedCookieValueSplittedArray = decodedCookieValue.split('/');
        return decodedCookieValueSplittedArray[2];
      }
    }
    return '';
  }

  @HostListener('window:scroll')
  handleScroll() {
    this.sticky = (window.pageYOffset >= this.menuElement.nativeElement.offsetTop - 5) ? true : false;
  }

  ngAfterViewInit() {
    this.includeGoogleTransalteScript();
  }

  openDialogSelectedProducts() {
    const dialogRef = this.dialog.open(SelectedProductsDialogComponent, {
      data: {
        menuComponent: this
      },
      autoFocus: false
    });
  }

  saveMenuStyleColorCustomBackgroundColor(color: string) {
    this.document.documentElement.style.setProperty('--menuStyleColor_custom_background-color', color);
  }

  saveMenuStyleColorCustomTextColor(color: string) {
    this.document.documentElement.style.setProperty('--menuStyleColor_custom_text-color', color);
  }

  saveMenuStyleColorCustomAccentColor(color: string) {
    this.document.documentElement.style.setProperty('--menuStyleColor_custom_accent-color', color);
  }

  includeGoogleTransalteScript() {
    const head = this.document.getElementsByTagName('head')[0];
    const js = this.document.createElement('script');
    js.type = 'text/javascript';
    js.appendChild(this.document.createTextNode("function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: '" + this.defaultLanguage + "', includedLanguages : ''}, 'google_translate_element'); }"));
    head.appendChild(js);

    const js2 = this.document.createElement('script');
    js2.type = 'text/javascript';
    js2.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    js2.appendChild(this.document.createTextNode(''));
    head.appendChild(js2);
  }

  buttonTranslateToggle() {
    if (this.buttonTranslate) {
      this.buttonTranslate = false;

      if(this.currentlanguage != this.defaultLanguage) {
        this.resetGoogTranslateCookie();
        location.reload();
      }
    } else {
      this.buttonTranslate = true;
    }
  }

  resetGoogTranslateCookie() {
    this.cookieService.delete('googtrans', '/', '.hello-menu.com');
    this.cookieService.delete('googtrans');
  }

  resetGoogTranslateCookieOnPageRefresh() {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.resetGoogTranslateCookie();
        }
      });
  }

  public getNameFromCoronaContactInputLink(link: string): string {
    if (link.includes('luca-app')) {
      return 'luca';
    } else {
      return '';
    }
  }

  public menuSortSwitch(section: Section) {
    this.scrollToCategoryById(section.order.toString());

    if (this.menuSortState === '') {
      this.menuSortState = 'asc';
      this.sessionStorage.saveValueToSessionStorage(this.sessionStorage.storageElementNameSelectedSorting, 'asc');
      this.snackBar.open('Preis aufsteigend', '', {duration: 1000, verticalPosition: 'top'});
    } else if (this.menuSortState === 'asc') {
      this.menuSortState = 'desc';
      this.sessionStorage.saveValueToSessionStorage(this.sessionStorage.storageElementNameSelectedSorting, 'desc');
      this.snackBar.open('Preis absteigend', '', {duration: 1000, verticalPosition: 'top'});
    } else if (this.menuSortState === 'desc') {
      this.menuSortState = '';
      this.sessionStorage.removeFromSessionStorage(this.sessionStorage.storageElementNameSelectedSorting);
      this.snackBar.open('Standard Sortierung', '', {duration: 1000, verticalPosition: 'top'});
    }
  }

  public showSortForSection(section: Section): boolean {
    var result = false;

    if (section.products.filter(product => product.hidden === false && product.price !== 0).length > 0) {
      result = true;
    }

    return result;
  }

  public menuFilterSwitch(filter: string) {
    this.scrollToFilterBlockElement();

    if (this.menuCurrentFiltersArray.includes(filter)) {
      this.menuCurrentFiltersArray = [];
      this.sessionStorage.removeFromSessionStorage(this.sessionStorage.storageElementNameSelectedFilter);
    } else {
      this.menuCurrentFiltersArray = [];
      this.menuCurrentFiltersArray.push(filter);
      this.sessionStorage.saveValueToSessionStorage(this.sessionStorage.storageElementNameSelectedFilter, filter);
    }

    // reset array, otherwise filter pipe doesn't detect changes
    // https://angular.io/guide/pipes
    this.menuCurrentFiltersArray = Object.assign([], this.menuCurrentFiltersArray);
  }

  public scrollToTop() {
    window.scroll({top: 0, behavior: 'smooth'});
  }

  public scrollToFilterBlockElement() {
    this.filterBlockElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  public scrollToElementById(elementId: string) {
    this.document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
  }

  public scrollToCategoryById(elementId: string) {
    const yOffset = -80;
    const element = document.getElementById('productSection-' + elementId);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  public checkIfCategoryIsSelected(elementId: string): boolean {

    var productSectionNodes = document.querySelectorAll('[id^="productSection-"]');

    var productSectionIds: string[] = Array.prototype.map.call(productSectionNodes, function(element, i) {
      return element.id;
    });
    const nextElementDivId = productSectionIds[productSectionIds.indexOf('productSection-' + elementId) + 1];

    const yOffset = -100 - 5;
    let result = false;
    const element = document.getElementById('productSection-' + elementId);
    const nextElement = document.getElementById(nextElementDivId);

    if (element && nextElement) {
      result = element.getBoundingClientRect().top + window.pageYOffset + yOffset < window.pageYOffset && window.pageYOffset < nextElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    } else if (element && !nextElement) {
      result = element.getBoundingClientRect().top + window.pageYOffset + yOffset < window.pageYOffset;
    }

    if (element && result && this.lastSelectionElementId != elementId) {
      const chip1Element = document.getElementById('chipForSection-' + productSectionIds[0].substring(productSectionIds[0].indexOf('-') + 1));
      const chipElement = document.getElementById('chipForSection-' + elementId);

      document.getElementById('stickyMenuContent').scrollTo({
        top: 0,
        left: chipElement.getBoundingClientRect().left - chip1Element.getBoundingClientRect().left - 1,
        behavior: 'smooth'
      });

    }

    if (element && result) {
      this.lastSelectionElementId = elementId;
    }

    return result;
  }

  public selectRow(section: Section, product: Product) {
    product.select = product.select !== true;

    if (product.select) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
    }

    this.sessionStorage.saveArrayToSessionStorage(this.sessionStorage.storageElementNameSelectedProducts, this.selectedProducts);
  }

  public getNumberOfSelectedRows(): number {
    let result = 0;

    result = this.selectedProducts.length;

    return result;
  }

  public getTotalPrice(): number {
    let totalPrice = 0;

    this.selectedProducts.forEach(product => {
      totalPrice = totalPrice + product.price;
    });

    return totalPrice;
  }

  lightenHexColor(hexColor: string, amount: number): string {
    return '#' + hexColor.replace(/^#/, '').replace(/../g, color => (
      '0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
  }

  generateSelectedMenuRowColorFromBackgroundColor(backgroundColor: string): string {
    // 40 darker
    let newHexColor: string = this.lightenHexColor(backgroundColor, -40);
    if (newHexColor === '#000000') {
      // 75 lighter
      newHexColor = this.lightenHexColor(backgroundColor, 75);
    }
    return newHexColor;
  }

  generateIconColorFromBackgroundColor(backgroundColor: string): string {
    // 60 darker
    let newHexColor: string = this.lightenHexColor(backgroundColor, -80);
    if (newHexColor === '#000000') {
      // 100 lighter
      newHexColor = this.lightenHexColor(backgroundColor, 130);
    }
    return newHexColor;
  }

  isColorLightOrDark(backgroundColor: string): string {
    let result = '';
    // 50 darker
    let newHexColor: string = this.lightenHexColor(backgroundColor, -50);
    if (newHexColor === '#000000') {
      result = 'dark';
    } else {
      result = 'light';
    }
    return result;
  }

  setSelectedMenuRowColor(menuStyleColor: string) {
    if (menuStyleColor === 'light') {
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_icon', this.generateIconColorFromBackgroundColor('#FDF8F5'));
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_selected-menu-row', this.generateSelectedMenuRowColorFromBackgroundColor('#FDF8F5'));
      this.document.documentElement.style.setProperty(
        '--viewChip_backgroundColor', '#ffffff');
      this.document.documentElement.style.setProperty(
        '--viewChip_textColor', 'rgba(0,0,0,.87)');
    } else if (menuStyleColor === 'dark') {
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_icon', this.generateIconColorFromBackgroundColor('#241f1c'));
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_selected-menu-row', this.generateSelectedMenuRowColorFromBackgroundColor('#241f1c'));
      this.document.documentElement.style.setProperty(
        '--viewChip_backgroundColor', '#241f1c');
      this.document.documentElement.style.setProperty(
        '--viewChip_textColor', '#e7dac7');
    } else if (menuStyleColor === 'custom') {
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_icon', this.generateIconColorFromBackgroundColor(this.menu.menuStyle.customColor.backgroundColor));
      this.document.documentElement.style.setProperty(
        '--menuStyleColor_selected-menu-row', this.generateSelectedMenuRowColorFromBackgroundColor(this.menu.menuStyle.customColor.backgroundColor));
      this.document.documentElement.style.setProperty(
        '--viewChip_backgroundColor', this.menu.menuStyle.customColor.backgroundColor);
      this.document.documentElement.style.setProperty(
        '--viewChip_textColor', this.menu.menuStyle.customColor.textColor);
    }
  }

  private getMenu(urlId: string) {
    this.menuService.getMenu(urlId)
      .subscribe(resultRest => {
        if (resultRest.status === 200) {
          this.menu = resultRest.body;

          if (this.menu.menuStyle.color === '') {
            this.menu.menuStyle.color = 'light';
          }
          this.initialMenuStyleColor = this.menu.menuStyle.color;

          if (this.menu.menuStyle.color === 'custom') {
            if (this.menu.menuStyle.customColor.backgroundColor === '') {
              this.menu.menuStyle.customColor.backgroundColor = '#f7f2ef';
            }
            if (this.menu.menuStyle.customColor.textColor === '') {
              this.menu.menuStyle.customColor.textColor = '#000000';
            }
            if (this.menu.menuStyle.customColor.accentColor === '') {
              this.menu.menuStyle.customColor.accentColor = '#D46111';
            }

            this.saveMenuStyleColorCustomBackgroundColor(this.menu.menuStyle.customColor.backgroundColor);
            this.saveMenuStyleColorCustomTextColor(this.menu.menuStyle.customColor.textColor);
            this.saveMenuStyleColorCustomAccentColor(this.menu.menuStyle.customColor.accentColor);
            this.menuStyleColorCustomBackgroundColorLightOrDark = this.isColorLightOrDark(this.menu.menuStyle.customColor.backgroundColor);
          }

          this.setSelectedMenuRowColor(this.menu.menuStyle.color);

          if(this.sessionStorage.getValueFromSessionStorage(this.sessionStorage.storageElementNameSelectedFilter) && this.menu.activeMetaTags.includes(this.sessionStorage.getValueFromSessionStorage(this.sessionStorage.storageElementNameSelectedFilter))) {
            this.menuCurrentFiltersArray.push(this.sessionStorage.getValueFromSessionStorage(this.sessionStorage.storageElementNameSelectedFilter));
          }

          if(this.sessionStorage.getArrayFromSessionStorage(this.sessionStorage.storageElementNameSelectedProducts)) {
            this.selectedProducts = this.sessionStorage.getArrayFromSessionStorage(this.sessionStorage.storageElementNameSelectedProducts);
            
            this.menu.sections.forEach(section => {
              section.products.forEach(product => {
                if (this.selectedProducts.filter(item => item.order === product.order).length > 0) {
                  product.select = true;
                }
              });
            });
          }
        } else {
          this.router.navigateByUrl('/not-found', {skipLocationChange: true});
        }
      }, error => {
        this.router.navigateByUrl('/not-found', {skipLocationChange: true});
      });
  }
}

