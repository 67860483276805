import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/translation/translation.service';

@Component({
  selector: 'app-food-additives',
  templateUrl: './food-additives.component.html',
  styleUrls: ['./food-additives.component.css']
})
export class FoodAdditivesComponent implements OnInit {

  constructor(public translationService: TranslationService) { }

  ngOnInit() {
  }

}
