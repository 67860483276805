import {HttpResponse} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import { Globals } from 'src/app/models/globals';
import {User} from 'src/app/models/user';
import {FileService} from 'src/app/services/file.service';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  @Input()
  demo = false;

  user = new User();

  constructor(private fileService: FileService, private userService: UserService) {
  }

  ngOnInit() {
    if (!this.demo) {
      this.userService.getUser().subscribe(result => {
        this.user = result;
      });
    } else {
      this.user =  {
        userDisplayName: 'demo',
        urlId: Globals.demoUrlId
      }
    }
  }

  public getUserUrlId(): string {
    return this.user.urlId;
  }


  public downloadQrCode(event: any) {
    event.stopPropagation();
    this.fileService.downloadFile(this.getQrCodeRestLink()).subscribe((response: HttpResponse<Blob>) => {
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
        downloadLink.setAttribute('download', 'Hello-Menu-QR-Code-' + this.user.urlId + '.jpg');
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    );
  }

  public getQrCodeRestLink(): string {
    return 'https://chart.googleapis.com/chart?cht=qr&chs=260x260&choe=UTF-8&chl=https://hello-menu.com/' + this.getUserUrlId();
  }

}
