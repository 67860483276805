import {Section} from './section';

export class Menu {
  name: string;
  urlId: string;
  description: string;
  logoUrl: string;
  coronaContactInputLink: string;
  activeMetaTags: string[];
  settingPriceSortEnabled: boolean;
  settingPriceTotalEnabled: boolean;
  settingGoogleAutoTranslate: boolean;
  sections: Section[];
  menuStyle: MenuStyle;
  modified: Date;
  created: Date;

  constructor() {
    this.menuStyle = { color: '', customColor: undefined };
  }
}


export class MenuStyle {
  color: string;
  customColor: CustomColor;
}

export class CustomColor {
  backgroundColor: string;
  textColor: string;
  accentColor: string;
}
