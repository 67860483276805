import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/translation/translation.service';

@Component({
  selector: 'app-my-menus',
  templateUrl: './my-menus.component.html',
  styleUrls: ['./my-menus.component.css']
})
export class MyMenusComponent implements OnInit {

  menus = [
    { "menuName": 'Speisekarte', "subMenu": false, "orderNumber": 0 },
    { "menuName": 'Lunchkarte', "subMenu": false, "orderNumber": 1 },
    { "menuName": 'Hotelinfo', "subMenu": false, "orderNumber": 2 },
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menus, event.previousIndex, event.currentIndex);
  }

  constructor(public translationService: TranslationService) { }

  ngOnInit() {
  }

  subMenuToggle(menu: any) {
    if (menu.orderNumber !== 0) { //TODO
      if (menu.subMenu) {
        menu.subMenu = false;
      } else {
        menu.subMenu = true;
      }
    }
  }

}
