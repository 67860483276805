import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-redirect-to-my-menu',
  templateUrl: './redirect-to-my-menu.component.html',
  styleUrls: ['./redirect-to-my-menu.component.css']
})
export class RedirectToMyMenuComponent implements OnInit {
  
  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(user => {
      window.location.href = '/' + user.urlId;
    });
  }

}
