import {Menu} from './menu';

export class Restaurant {
  userId: number;
  urlId: string;
  name: string;
  description: string;
  menuDraft: Menu;
  modified: Date;
  created: Date;
}
