import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class HmApiService {

    constructor(private http: HttpClient) {
    }

    public setLanguage(language: string) {
        // return this.http.get('https://home.hello-menu.com/wp-json/hm-api/set-language/' + language);
        var closeWindow= window.open('https://home.hello-menu.com/wp-json/hm-api/set-language/' + language);

        setTimeout (function closeTab() {
            closeWindow.close();
        }, 100);
    }
}