import {Product} from './product';

export class Section {
  name: string;
  description: string;
  isSubsection: boolean;
  order: number;
  products: Product[];
  hidden: boolean = false;
  metaTags: string[];
}
