import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-to-website',
  templateUrl: './redirect-to-website.component.html',
  styleUrls: ['./redirect-to-website.component.css']
})
export class RedirectToWebsiteComponent implements OnInit {

  constructor() {
    window.location.href = 'https://home.hello-menu.com';
  }

  ngOnInit() {
  }

}
