import {Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiCookie } from '../models/apiCookie';
import { Restaurant } from '../models/restaurant';
import {User} from '../models/user';
import {CookieService} from './cookie.service';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private cookieService: CookieService, private menuService: MenuService) {
  }

  public getUser(): Observable<User> {
    const apiCookie: ApiCookie = this.cookieService.getCookie();

    const redirectUrl = 'https://home.hello-menu.com/log-in/';

    var observableSubject = new Subject<User>();

    if (apiCookie.jwtToken === '') {
      window.location.href = redirectUrl;
      observableSubject.next(null);
    } else {
      this.menuService.getRestaurant().subscribe(restaurant => {
        if (restaurant.status === 200 && restaurant.body.userId !== 0) {
          const user: User = { userDisplayName: apiCookie.userDisplayName, urlId: restaurant.body.urlId};
          observableSubject.next(user);
        } else {
          window.location.href = redirectUrl;
          observableSubject.next(null);
        }
      }, error => {
          window.location.href = redirectUrl;
          observableSubject.next(null);
      });
    }

    return observableSubject.asObservable();
  }
}
