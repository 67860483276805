import {Component, OnInit} from '@angular/core';
import {User} from 'src/app/models/user';
import {UserService} from 'src/app/services/user.service';
import { Globals } from 'src/app/models/globals'; // important for html template

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  user = new User();

  openMobileMenu = false;
  openMobileMenuMeinBereich = false;

  demoUrlId = Globals.demoUrlId;

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(result => {
      this.user = result;
    });
  }
}
