import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-menu-revert-dialog',
  templateUrl: './menu-revert-dialog.component.html',
  styleUrls: ['./menu-revert-dialog.component.css']
})
export class MenuRevertDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
