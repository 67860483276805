import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MenuComponent} from './components/menu/menu.component';
import {MenuFilterPipe, MenuSortPipe, MenuVisibilityPipe} from './services/menu.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {HttpClientModule} from '@angular/common/http';
import {MenuEditComponent} from './components/menu-edit/menu-edit.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MenuPublishDialogComponent} from './components/dialogs/menu-publish-dialog/menu-publish-dialog.component';
import {MenuRevertDialogComponent} from './components/dialogs/menu-revert-dialog/menu-revert-dialog.component';
import {FooterComponent} from './components/footer/footer.component';
import {UserHeaderComponent} from './components/user-header/user-header.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {FormsModule} from '@angular/forms';
import {FoodAdditivesComponent} from './components/menu/food-additives/food-additives.component';
import {DemoQrCodeComponent} from './components/demo/demo-qr-code/demo-qr-code.component';
import {DemoMenuEditComponent} from './components/demo/demo-menu-edit/demo-menu-edit.component';
import {MenuPrintComponent} from './components/menu/menu-print/menu-print.component';
import {RedirectToWebsiteComponent} from './components/redirect-to-website/redirect-to-website.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {RedirectToMyMenuComponent} from './components/redirect-to-my-menu/redirect-to-my-menu.component';
import {
  RedirectToMyMenuPrintComponent
} from './components/redirect-to-my-menu-print/redirect-to-my-menu-print.component';
import {
  MenuDeleteGroupDialogComponent
} from './components/dialogs/menu-delete-group-dialog/menu-delete-group-dialog.component';
import {
  SelectedProductsDialogComponent
} from './components/menu/selected-products-dialog/selected-products-dialog.component';
import {
  FoodAdditivesDialogComponent
} from './components/menu-edit/food-additives-dialog/food-additives-dialog.component';
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {MyMenusComponent} from './components/my-menus/my-menus.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    MenuFilterPipe,
    MenuSortPipe,
    MenuEditComponent,
    MenuPublishDialogComponent,
    MenuRevertDialogComponent,
    FooterComponent,
    UserHeaderComponent,
    ToolbarComponent,
    QrCodeComponent,
    FoodAdditivesComponent,
    DemoQrCodeComponent,
    DemoMenuEditComponent,
    MenuPrintComponent,
    MenuVisibilityPipe,
    RedirectToWebsiteComponent,
    NotFoundComponent,
    RedirectToMyMenuComponent,
    RedirectToMyMenuPrintComponent,
    MenuDeleteGroupDialogComponent,
    SelectedProductsDialogComponent,
    FoodAdditivesDialogComponent,
    LanguageSwitcherComponent,
    MyMenusComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatChipsModule,
    DragDropModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatSlideToggleModule,
    FormsModule,
    MatProgressBarModule,
    MatSelectModule
  ],
  entryComponents: [MenuPublishDialogComponent, MenuRevertDialogComponent, MenuDeleteGroupDialogComponent, SelectedProductsDialogComponent, FoodAdditivesDialogComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
