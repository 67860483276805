import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from 'src/app/models/product';
import { MenuComponent } from '../menu.component';
import { TranslationService } from 'src/app/translation/translation.service';

@Component({
  selector: 'app-selected-products-dialog',
  templateUrl: './selected-products-dialog.component.html',
  styleUrls: ['./selected-products-dialog.component.css']
})
export class SelectedProductsDialogComponent implements OnInit {

  menuComponent: MenuComponent;

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: any,
              private dialogRef: MatDialogRef<SelectedProductsDialogComponent>,
              public translationService: TranslationService) { }

  ngOnInit() {
    this.menuComponent = this.dialogData.menuComponent;
  }

  public deselectProduct(product: Product) {
    this.menuComponent.menu.sections.filter(section => section.products !== null).forEach(section => {
      section.products.forEach(element => {
        if (element.select === true && element === product) {
          element.select = false;
        }
      });
    });

    if(this.menuComponent.selectedProducts.length > 1) {
      this.menuComponent.selectedProducts = this.menuComponent.selectedProducts.filter(element => element !== product);
      this.menuComponent.sessionStorage.saveArrayToSessionStorage(this.menuComponent.sessionStorage.storageElementNameSelectedProducts, this.menuComponent.selectedProducts);
    } else {
      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(() => {
        this.menuComponent.selectedProducts = this.menuComponent.selectedProducts.filter(element => element !== product);
        this.menuComponent.sessionStorage.saveArrayToSessionStorage(this.menuComponent.sessionStorage.storageElementNameSelectedProducts, this.menuComponent.selectedProducts);
      });
    }
  }

  public deselectAllProducts() {
    this.menuComponent.menu.sections.filter(section => section.products !== null).forEach(section => {
      section.products.forEach(element => {
        if (element.select === true) {
          element.select = false;
        }
      });
    });

    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.menuComponent.selectedProducts = [];
      this.menuComponent.sessionStorage.removeFromSessionStorage(this.menuComponent.sessionStorage.storageElementNameSelectedProducts);
    });
  }

}
