
import {Injectable} from '@angular/core';
import * as menuTranslation from './menu-translation.json';
import * as appTranslation from './app-translation.json';
import { CookieService } from '../services/cookie.service';
import { HmApiService } from '../services/hm-api.service';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

    private appLanguage = '';

    private menuLanguage = 'de';
    private menuCurrency = '€';

    constructor(private cookieService: CookieService,
                private hmApiService: HmApiService) {
        const cookieLanguage = this.cookieService.get('trp_language').substring(0, 2);

        if (cookieLanguage === '') {
            this.appLanguage = 'de';
        } else {
            this.appLanguage = cookieLanguage;
        }
    }

    //TODO
    public getMenuLanguage(): string {
        return this.menuLanguage;
    }

    //TODO
    public getMenuCurrency(): string {
        return this.menuCurrency;
    }

    public getAppLanguage(): string {
        return this.appLanguage;
    }

    public setAppLanguage(language: string) {
        this.appLanguage = language;

        const cookieLanguageCode = appTranslation.languagesCookieCode[this.appLanguage];

        this.hmApiService.setLanguage(cookieLanguageCode);
    }

    public getMenuTranslation(group: string, subgroup: string, element: string): string {
        return menuTranslation.translation[group][subgroup][element][this.menuLanguage];
    }

    public getAppTranslation(group: string, subgroup: string, element: string): string {
        return appTranslation.translation[group][subgroup][element][this.appLanguage];
    }

    public getMenuLanguages(): Language[] {
        return menuTranslation.languages;
    }

    public getMenuCurrencies(): Language[] {
        return menuTranslation.currencies;
    }

    public getAppLanguages(): Language[] {
        return menuTranslation.languages;
    }
}
