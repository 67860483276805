import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {Menu} from '../models/menu';
import {Product} from '../models/product';
import {Restaurant} from '../models/restaurant';
import {CookieService} from './cookie.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient, private cookieService: CookieService) {
  }

  public getMenu(urlId: string): Observable<HttpResponse<Menu>> {
    // const url = './../assets/menu.mock.json';
    const url = environment.apiUrl + '/menus/' + urlId;
    return this.http.get<Menu>(url, {observe: 'response'});
  }

  public getRestaurant(): Observable<HttpResponse<Restaurant>> {
    // const url = './../assets/menu.mock.json';
    const header = {Authorization: `Bearer ${this.cookieService.getCookie().jwtToken}`};
    const url = environment.apiUrl + '/restaurants';
    return this.http.get<Restaurant>(url, {headers: header, observe: 'response'});
  }

  public putMenu(menu: Menu): Observable<HttpResponse<any>> {
    const header = {Authorization: `Bearer ${this.cookieService.getCookie().jwtToken}`};
    const url = environment.apiUrl + '/menus';
    return this.http.put(url, menu, {headers: header, observe: 'response'});
  }

  public putRestaurant(restaurant: Restaurant): Observable<HttpResponse<any>> {
    const header = {Authorization: `Bearer ${this.cookieService.getCookie().jwtToken}`};
    const url = environment.apiUrl + '/restaurants';
    return this.http.post(url, restaurant, {headers: header, observe: 'response'});
  }

}

@Pipe({name: 'menuSortPipe'})
export class MenuSortPipe implements PipeTransform {
  transform(products: Product[], order: string): Product[] {
    if (!products) {
      return [];
    }

    if (order === 'asc' || order === 'desc') {
      products.sort((a, b) => a.price > b.price ? 1 : -1);

      if (order === 'desc') {
        products.reverse();
      }

      return products;
    } else {
      products.sort((a, b) => a.order > b.order ? 1 : -1);

      return products;
    }
  }
}

@Pipe({name: 'menuFilterPipe'})
export class MenuFilterPipe implements PipeTransform {
  transform(sectionsOrProducts: any[], menuCurrentFiltersArray: any[]): Product[] {
    if (menuCurrentFiltersArray.length === 0) {
      return sectionsOrProducts;
    } else {
      if (sectionsOrProducts !== null && sectionsOrProducts !== undefined && sectionsOrProducts.length > 0) {
        return sectionsOrProducts.filter(item =>
          item.metaTags !== null && menuCurrentFiltersArray.every(menuCurrentFiltersArrayElement => item.metaTags.includes(menuCurrentFiltersArrayElement))
        );
      }
    }
  }
}

@Pipe({name: 'menuVisibilityPipe'})
export class MenuVisibilityPipe implements PipeTransform {
  transform(sectionsOrProducts: any[]): any[] {
    if (sectionsOrProducts !== undefined && sectionsOrProducts !== null) {
      return sectionsOrProducts.filter(item => item.hidden !== true);
    }

  }
}
