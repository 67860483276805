import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-menu-publish-dialog',
  templateUrl: './menu-publish-dialog.component.html',
  styleUrls: ['./menu-publish-dialog.component.css']
})
export class MenuPublishDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
