import {Component, OnInit} from '@angular/core';
import {User} from 'src/app/models/user';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {

  user = new User();

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe(result => {
      this.user = result;
    });
  }

}
