import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/translation/translation.service';

@Component({
  selector: 'app-demo-menu-edit',
  templateUrl: './demo-menu-edit.component.html',
  styleUrls: ['./demo-menu-edit.component.css']
})
export class DemoMenuEditComponent implements OnInit {

  constructor(public translationService: TranslationService,
              private route: ActivatedRoute) { }

  ngOnInit() {
  }

}
