import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { DemoMenuEditComponent } from './components/demo/demo-menu-edit/demo-menu-edit.component';
import { DemoQrCodeComponent } from './components/demo/demo-qr-code/demo-qr-code.component';
import {MenuEditComponent} from './components/menu-edit/menu-edit.component';
import { FoodAdditivesComponent } from './components/menu/food-additives/food-additives.component';
import { MenuPrintComponent } from './components/menu/menu-print/menu-print.component';
import {MenuComponent} from './components/menu/menu.component';
import { MyMenusComponent } from './components/my-menus/my-menus.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { RedirectToMyMenuPrintComponent } from './components/redirect-to-my-menu-print/redirect-to-my-menu-print.component';
import { RedirectToMyMenuComponent } from './components/redirect-to-my-menu/redirect-to-my-menu.component';
import { RedirectToWebsiteComponent } from './components/redirect-to-website/redirect-to-website.component';


const routes: Routes = [
  { path: 'menu-edit', component: MenuEditComponent },
  { path: 'my-menus', component: MyMenusComponent },
  { path: 'qr-code', component: QrCodeComponent },
  { path: 'food-additives', component: FoodAdditivesComponent },
  { path: 'demo/menu-edit', component: DemoMenuEditComponent },
  { path: 'demo/qr-code', component: DemoQrCodeComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'my-menu/print', component: RedirectToMyMenuPrintComponent },
  { path: 'my-menu', component: RedirectToMyMenuComponent },
  { path: ':id/print', component: MenuPrintComponent },
  { path: ':id', component: MenuComponent },
  { path: '', component: RedirectToWebsiteComponent },
  { path: '**', component: NotFoundComponent } // means any other url
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
